import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse } from "../../../utils/api/response-handler";
import { Evaluator } from "../model/evaluator";

export function getEvaluators(moduleId?: string | undefined): Promise<Evaluator[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/list?moduleId=${moduleId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Evaluator[]>(response)
        });
}

export function getEvaluator(evaluatorId: number): Promise<Evaluator> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/${evaluatorId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Evaluator>(response)
        });
}

export function updateEvaluatorModules(evaluatorId: number, moduleIds: string[]): Promise<Evaluator> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/evaluator/${evaluatorId}/module`, requestOptions(HttpMethod.POST, moduleIds))
        .then(response => {
            return handleResponse<Evaluator>(response)
        });
}