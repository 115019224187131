export enum Role {
    Administrator,
    BetaTester,
    Evaluator,
    CourseEditor,
    SuperEvaluator,
    ATLead,
    LeadTrainer,
    Registrar,
    Student,
    Trainer,
    QualiMaster,
    Trainee,
    CohortCaptain,
    ExamineManager
}

export const roles = Object.values(Role).filter((v) => !isNaN(Number(v))) as number[];

export const studentRoles = [Role.Student, Role.Trainee] as number[]