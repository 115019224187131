import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { User } from "../model/user";
import { getUserStudent } from "../service/student-service";
import ViewLoader from "../../../component/misc/view-loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import UserHandler from "./-student-handler/student-handler";
import { useCache } from "../../../context/cache/cache-provider";
import { PARAMETER_STUDENT_ID } from "../../../infrastructure/route";

export const STUDENT_TAB_STATE: string = "STUDENT_TAB_STATE";
export const STUDENT_TAB_STATE_ADD_EDIT: string = "STUDENT_TAB_STATE_ADD_EDIT";

export default function StudentView() {
    const { studentId } = useParams();
    const newModel = studentId === undefined;

    const [tab, setTab] = useState<string>(localStorage.getItem(STUDENT_TAB_STATE) ?? STUDENT_TAB_STATE_ADD_EDIT);
    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState<User>({
        id: Number(studentId ?? "0"),
        name: "",
        email: "",
        phone: "",
        roles: [],
        active: true
    });

    const cache = useCache();

    useEffect(() => {
        cache.update({ key: PARAMETER_STUDENT_ID, value: student.name });
    }, [cache, student]);

    useEffect(() => {
        if (!newModel) {
            setLoading(true);
            getUserStudent(Number(studentId)).then(result => {
                setStudent(result);
                setLoading(false);
            })
        } else {
            setLoading(false);
        }
    }, [studentId, newModel]);

    const changeTab = (newTab: string) => {
        localStorage.setItem(STUDENT_TAB_STATE, newTab);
        setTab(newTab);
    }

    return (
        <React.Fragment>
            <ViewLoader loading={loading} />
            {loading === false && <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(_event: React.SyntheticEvent, newTab: string) => changeTab(newTab)}>
                        <Tab label={newModel === true ? "Add" : "Edit"} value={STUDENT_TAB_STATE_ADD_EDIT} />
                    </TabList>
                </Box>
                <TabPanel value={STUDENT_TAB_STATE_ADD_EDIT}>
                    <UserHandler
                        newModel={newModel}
                        student={student}
                        setStudent={setStudent}
                    />
                </TabPanel>
            </TabContext>}
        </React.Fragment>
    );
}
