import { useEffect, useState } from 'react';
import commonStyles from "../../../style/common.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ModuleAssignment } from '../../model/module-assignment';
import { BadgeAssignment } from '../../model/badge-assignment';
import { LoadingButton } from '@mui/lab';
import { useDialog } from '../../../../context/dialog/dialog-provider';
import { DialogType, informationText } from '../../../../context/dialog/dialog-context-type';
import { getNumber } from '../../../../utils/helper/string-helper';
import EvaluatorJournalBadgeContent from './evaluator-journal-badge-content';
import { AssignmentStatus } from '../../enum/assignment-status';
import { useSearchParams } from 'react-router-dom';
import {
    ASSIGNMENT_QUERY_PARAMETER_MODULEID,
    ASSIGNMENT_QUERY_PARAMETER_TAB,
    ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL,
    ASSIGNMENT_QUERY_PARAMETER_WORKITEMID
} from '../../utils/assignment-constants';

export default function EvaluatorJournal({
    editHourLimit,
    moduleAssignments,
    updateModuleAssignments
}: {
    editHourLimit: number,
    moduleAssignments: ModuleAssignment[] | undefined,
    updateModuleAssignments(badgeAssignment: BadgeAssignment): void
}) {
    const modules = moduleAssignments?.filter(_ => _.badges.filter(b => b.submissionDate !== undefined && b.evaluatedDate !== undefined).length >= 1) ?? [] as ModuleAssignment[]

    const [searchParams, setSearchParams] = useSearchParams();
    const moduleId = searchParams.get(ASSIGNMENT_QUERY_PARAMETER_MODULEID);
    const workItemId = Number(searchParams.get(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID));
    const [badge, setBadge] = useState<BadgeAssignment>(modules.find(_ => _.moduleId === moduleId)?.badges.find(_ => _.workItemId === workItemId) ?? {});

    const dialog = useDialog();

    useEffect(() => {
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_TAB, ASSIGNMENT_QUERY_PARAMETER_TAB_VALUE_JOURNAL);
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_MODULEID, badge.moduleId ?? "");
        searchParams.set(ASSIGNMENT_QUERY_PARAMETER_WORKITEMID, badge.workItemId?.toString() ?? "");
        setSearchParams(searchParams);
    }, [badge, searchParams, setSearchParams]);

    const getBadges = () => {
        const module = moduleAssignments?.find(_ => _.moduleId === badge.moduleId);
        return module?.badges.filter(_ => _.submissionDate !== undefined && _.evaluatedDate !== undefined) ?? [] as BadgeAssignment[];
    }

    const handleBadgeSelect = (badgeAssignment: BadgeAssignment) => {
        if (badgeAssignment.workItemId !== badge.workItemId) {
            setBadge(badgeAssignment);
        } else {
            setBadge({ ...badge, workItemId: undefined });
        }
    }

    const openHelpInformationDialog = () => {
        dialog.openDialog(DialogType.INFORMATION, "Help / Information", [
            informationText("In the list of <b>Session submissions</b> review, you can see the <b>rating</b> you gave. It will be empty until you leaves a rating, or a comment."),
            informationText("The list of <b>Session submissions</b> review are <b>ordered by review time</b>, the more recent review the higher in the list."),
            informationText("You can review a <b>Session submission</b> with a <b>comment and no rating</b>, informing the explorer about something. A comment icon will indicate this in the list."),
            informationText("After you opened a <b>Session submission</b> review then you can <b>edit</b> it. To do so, click on the menu in the top right corner of the Session submission review, there you will find the edit option."),
            informationText(`After you added a rating to a <b>Session submission</b> review then you can only <b>edit it for ${editHourLimit-24} hours</b>, after which it is no longer possible.`)
        ]);
    }

    return (
        <div className={commonStyles.pb} >
            {modules.length > 0 && <FormControl id={`module-form-control`} sx={{ flex: '1 1 100%', alignSelf: 'flex-start', width: '100%', paddingBottom: '1rem' }}>
                <InputLabel id={`module-label`}>Foundation</InputLabel>
                <Select
                    labelId={`module-label`}
                    id={`module-select`}
                    value={badge.moduleId ?? ''}
                    label='Foundation'
                    onChange={e => setBadge({
                        ...badge,
                        moduleId: e.target.value,
                        workItemId: undefined
                    })}
                >
                    {modules.map(_ => {
                        return <MenuItem key={_.moduleId} id={`${_}-menu-item`} value={_.moduleId}>
                            {_.moduleName}
                        </MenuItem>
                    })}
                </Select>
            </FormControl>}
            {modules.length === 0 && <div>
                <span>Your <b>Journal</b> is empty, when you have reviewed a session then you can view, and futher handle, it from here.</span>
            </div>}
            {badge.moduleId !== undefined && <div className={commonStyles.mb}>
                {getBadges().map(_ => {
                    return (<Accordion key={_.workItemId} expanded={badge.workItemId === _.workItemId} onChange={() => handleBadgeSelect(_)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{ width: '99%', flexShrink: 0, }}>
                                {_.badgeName}
                                {getNumber(_.rating) > 0 && Array.from({ length: getNumber(_.rating) }, () => <StarIcon fontSize="small" key={`${Math.random()}-select-icon`} color="primary" sx={{ float: 'right' }} />)}
                                {_.assignmentStatus === AssignmentStatus.Evaluated_Commented && <ChatBubbleIcon color="primary" sx={{ float: 'right' }} />}
                            </Typography>
                        </AccordionSummary>
                        {badge.workItemId === _.workItemId && <AccordionDetails>
                            <EvaluatorJournalBadgeContent
                                initialBadge={badge}
                                updateBadge={(badge) => {
                                    updateModuleAssignments(badge);
                                    setBadge(badge);
                                }} />
                        </AccordionDetails>}
                    </Accordion>)
                })}
            </div>}
            {modules.length > 0 && <div style={{ display: 'flex', justifyContent: 'end' }}>
                <LoadingButton
                    id={`info-button`}
                    loadingPosition="start"
                    startIcon={<HelpOutlineIcon />}
                    variant="outlined"
                    onClick={() => openHelpInformationDialog()}
                >
                    Info / Help
                </LoadingButton>
            </div>}
        </div>
    );
}