import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cohortFieldDefinitions } from "./cohort-field-definition";
import { Cohort, cohortDisplayName } from "../../model/cohort";
import { useToast } from "../../../../context/toast/toast-provider";
import { addCohort, deleteCohort, updateCohort } from "../../service/cohort-service";
import { EDUCATION_MANAGEMENT_COHORT_EDIT, EDUCATION_MANAGEMENT_COHORT_LIST, PARAMETER_COHORT_ID } from "../../../../infrastructure/route";
import BaseForm from "../../../../component/form/base-form";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { getUserStudents } from "../../../-account/service/student-service";

export default function CohortHandler({
    newModel,
    cohort,
    setCohort
}: {
    newModel: boolean,
    cohort: Cohort,
    setCohort: React.Dispatch<React.SetStateAction<Cohort>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();

    const getFieldDefinitions = (): FieldDefinition[] => {
        if (!newModel) {
            cohortFieldDefinitions.find(_ => _.id === "courseLevelId")!.disabled = true;
        } else {
            cohortFieldDefinitions.find(_ => _.id === "courseLevelId")!.disabled = false;
        }

        return cohortFieldDefinitions;
    }

    const handleSubmit = (cohort: Cohort) => {
        setSubmitting(true);

        if (newModel) {
            addCohort(cohort).then(result => {
                toast.addToast(`Cohort ${cohortDisplayName(result)} created`, "success");
                setCohort(result);
                setSubmitting(false);

                navigate(EDUCATION_MANAGEMENT_COHORT_EDIT.replace(PARAMETER_COHORT_ID, `${result.id}`));
            }).catch(() => {
                toast.addToast(`Unable to create cohort`, "error");
                setSubmitting(false);
            });
        } else {
            updateCohort(cohort).then(result => {
                toast.addToast(`Cohort ${cohortDisplayName(result)} updated`, "success");
                setCohort(result);
                setSubmitting(false);
            }).catch(() => {
                toast.addToast(`Unable to update ${cohortDisplayName(cohort)}`, "error");
                setSubmitting(false);
            });
        }
    }

    const deleteWarnings = () => {
        const warnings = [
            cohort.hasAttendance === true ? `<b>${cohort.name}</b> has registered student attendance, deleting the cohort will also <b>delete the attendance</b>.` : '',
            cohort.hasPresentations === true ? `<b>${cohort.name}</b> has registered presentations, deleting the cohort will also <b>delete the presentations</b>.` : '',
        ].filter(_ => _.length > 0)

        return warnings.length > 0 ? warnings : undefined;
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            getUserStudents(cohort.id!).then(result => {
                if (result === undefined || result.length === 0) {
                    deleteCohort(cohort.id!).then(() => {
                        toast.addToast(`Cohort ${cohortDisplayName(cohort)} deleted`, "success");
                        setDeleting(false);
                        navigate(EDUCATION_MANAGEMENT_COHORT_LIST);
                    }).catch(() => {
                        toast.addToast(`Unable to delete ${cohortDisplayName(cohort)}`, "error");
                        setDeleting(false);
                    });
                } else {
                    toast.addToast(`The cohort ${cohortDisplayName(cohort)} can not be deleted, it has ${result.length} Student(s). Unassign them first to delete the cohort.`, "warning");
                    setDeleting(false);
                }
            });
        }
    }

    return (
        <BaseForm
            name={cohort.name}
            model={cohort}
            setModel={setCohort}
            fields={getFieldDefinitions()}
            submitEntity={handleSubmit}
            deleteEntity={!newModel ? handleDelete : undefined}
            submitting={submitting}
            deleting={deleting}
            deleteWarnings={deleteWarnings()}
        />
    );
}
