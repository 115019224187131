import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./student-list-column-definition";
import commonStyles from "../../../style/common.module.css";
import { User } from "../../model/user";
import { getUserStudents } from "../../service/student-service";
import { ACCOUNT_MANAGEMENT_STUDENT_ADD, ACCOUNT_MANAGEMENT_STUDENT_EDIT, PARAMETER_STUDENT_ID } from "../../../../infrastructure/route";
import { STUDENT_TAB_STATE } from "../student-view";
import { useCache } from "../../../../context/cache/cache-provider";
import { BaseList } from "../../../../component/list/base-list";

export default function StudentList() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<User[]>([]);

    const navigate = useNavigate();
    const cache = useCache();

    useEffect(() => {
        setLoading(true);
        getUserStudents().then(result => {
            setUsers(result);
            setLoading(false);
        });
    }, []);

    const openAdd = () => {
        localStorage.removeItem(STUDENT_TAB_STATE);
        navigate(ACCOUNT_MANAGEMENT_STUDENT_ADD);
    }

    const openEdit = (studentId: number) => {
        const student = users.find(_ => _.id === Number(studentId));
        cache.update({ key: PARAMETER_STUDENT_ID, value: student?.name ?? '' });

        localStorage.removeItem(STUDENT_TAB_STATE);
        navigate(ACCOUNT_MANAGEMENT_STUDENT_EDIT.replace(PARAMETER_STUDENT_ID, studentId.toString()))
    }

    return (
        <div className={commonStyles.content} >
            <BaseList
                storageKey="STUDENT"
                rows={users}
                columns={columns}
                loading={loading}
                localeText={{ noRowsLabel: "No students" }}
                getRowId={(row) => row?.id}
                onRowClick={(e) => openEdit(e.row?.id)}
                onAddClick={() => openAdd()}
                addButtonText="Add student"
            />
        </div>
    );
}
