import { GridColDef } from "@mui/x-data-grid";
import { renderCommonRelation } from "../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'badgeTitle', headerName: 'Badge', flex: 2 },
    { field: 'value', headerName: 'Cohort rating', flex: 1 },
    { field: 'commonValue', headerName: 'Common rating', flex: 1 },
    { field: 'commonRelation', headerName: 'Relation', flex: 1, renderCell: (params) => { return renderCommonRelation(params.row.commonRelation) } }
];

