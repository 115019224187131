import { GridColDef } from "@mui/x-data-grid";
import { renderDate } from "../../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'cohortName', headerName: 'Cohort', flex: 1 },
    { field: 'moduleTitle', headerName: 'Module', flex: 1 },
    { field: 'badgeTitle', headerName: 'Badge', flex: 1 },
    { field: 'studentName', headerName: 'Student', flex: 1 },
    { field: 'studentEmailAddress', headerName: 'Email', flex: 1 },
    { field: 'submissionDate', headerName: 'Submission', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'evaluatedDate', headerName: 'Evaluated', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'rating', headerName: 'Rating', flex: 0.5 }
];