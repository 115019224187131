import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userFieldDefinitions } from "./user-field-definition";
import { User } from "../../model/user";
import { useToast } from "../../../../context/toast/toast-provider";
import { addUser, deleteUser, updateUser } from "../../service/user-service";
import { ACCOUNT_MANAGEMENT_USER_EDIT, ACCOUNT_MANAGEMENT_USER_LIST, PARAMETER_USER_ID } from "../../../../infrastructure/route";
import BaseForm from "../../../../component/form/base-form";

export default function UserHandler({
    newModel,
    user,
    setUser
}: {
        newModel: boolean,
        user: User,
        setUser: React.Dispatch<React.SetStateAction<User>>
}) {
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = (user: User) => {
        setSubmitting(true);

        if (newModel) {
            addUser(user).then(result => {
                toast.addToast(`User ${result.name} created`, "success");
                setUser(result);
                setSubmitting(false);

                navigate(ACCOUNT_MANAGEMENT_USER_EDIT.replace(PARAMETER_USER_ID, `${result.id}`))
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${user.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to create user`, "error");
                }
                setSubmitting(false);
            });
        } else {
            updateUser(user).then(result => {
                toast.addToast(`User ${result.name} updated`, "success");
                setUser(result);
                setSubmitting(false);
            }).catch((error) => {
                if (error?.apiErrorResponse?.errorCode === 409) {
                    toast.addToast(`The email ${user.email} already exists`, "error");
                } else {
                    toast.addToast(`Unable to update user ${user.name}`, "error");
                }
                setSubmitting(false);
            });
        }
    }

    const deleteWarnings = () => {
        const warnings = [
            user.hasAttendances === true ? `<b>${user.name}</b> has <b>attendance</b>, deleting the student will also <b>delete the attendance</b>.` : '',
            user.hasAssignments === true ? `<b>${user.name}</b> has <b>assignments</b>, deleting the student will also <b>delete the assignments</b>.` : '',
            user.hasPresentations === true ? `<b>${user.name}</b> has given <b>presentations</b>, deleting the user will also <b>delete the presentations</b>.` : '',
            user.hasEvaluationReviews === true ? `<b>${user.name}</b> as an evaluator has given <b>reviews</b>, deleting the user will also <b>delete the reviews</b>.` : '',
            user.hasEvaluationModulesAssigned === true ? `<b>${user.name}</b> as an evaluator is <b>assigned modules</b>, deleting the user will also <b>remove the modules assignments</b>.` : '',
            user.isStudent === true ? `<b>${user.name}</b> is registered as a <b>student</b>, deleting the user will also <b>delete the registration</b>.` : '',
            user.cohortId !== undefined ? `<b>${user.name}</b> as a student is attending a <b>cohort</b>, deleting the user will also <b>remove it from the cohort</b>.` : '',
        ].filter(_ => _.length > 0)

        return warnings.length > 0 ? warnings : undefined;
    }

    const handleDelete = () => {
        if (!newModel) {
            setDeleting(true);
            deleteUser(Number(user.id)).then(() => {
                toast.addToast(`User ${user.name} deleted`, "success");
                setDeleting(false);
                navigate(ACCOUNT_MANAGEMENT_USER_LIST);
            }).catch(() => {
                toast.addToast(`Unable to delete user ${user.name}`, "error");
                setDeleting(false);
            });
        }
    }

    return (
        <BaseForm
            name={user.name}
            model={user}
            setModel={setUser}
            fields={userFieldDefinitions}
            submitEntity={handleSubmit}
            deleteEntity={!newModel ? handleDelete : undefined}
            submitting={submitting}
            deleting={deleting}
            deleteWarnings={deleteWarnings()}
        />
    );
}
