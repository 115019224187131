import { FieldDefinition } from '../../../../component/form/field-definition';

export const attendanceFieldDefinitions: FieldDefinition[] = [
    {
        id: "day",
        name: "Day",
        type: "date",
        required: true,
        disabled: false
    },
    {
        id: "cohortId",
        name: "Cohort",
        type: "select",
        required: true,
        disabled: false,
        values: []
    }
];