import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Survey } from "../model/survey";

export function getSurveys(): Promise<Survey[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Survey[]>(response)
        });
}

export function getSurvey(surveyId: number): Promise<Survey> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/${surveyId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function addSurvey(survey: Survey): Promise<Survey> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey`, requestOptions(HttpMethod.POST, survey))
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function updateSurvey(survey: Survey): Promise<Survey> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey`, requestOptions(HttpMethod.PUT, survey))
        .then(response => {
            return handleResponse<Survey>(response)
        });
}

export function deleteSurvey(surveyId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/${surveyId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}