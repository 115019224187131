import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse } from "../../../utils/api/response-handler";
import { AttendanceOverview } from "../model/attendance-overview";
import { AttendanceUpdate } from "../model/attendance-update";
import { Student } from "../model/student";

export function getAttendances(from: string, to: string): Promise<AttendanceOverview[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/list/${from}/${to}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<AttendanceOverview[]>(response)
        });
}

export function getAttendance(day: string, cohortId: number | undefined = undefined): Promise<Student[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/${day}?cohortId=${cohortId ?? ''}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Student[]>(response)
        });
}

export function updateAttendances(attendanceUpdate: AttendanceUpdate): Promise<Student[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/attendance/${attendanceUpdate.day.format('YYYY-MM-DD') }`, requestOptions(HttpMethod.POST, attendanceUpdate))
        .then(response => {
            return handleResponse<Student[]>(response)
        });
}