import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Badge } from "../model/badge";

export function getBadges(moduleId: string): Promise<Badge[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/list/${moduleId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Badge[]>(response)
        });
}

export function getBadge(badgeId: string): Promise<Badge> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/${badgeId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function addBadge(badge: Badge): Promise<Badge> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge`, requestOptions(HttpMethod.POST, badge))
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function updateBadge(badge: Badge): Promise<Badge> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge`, requestOptions(HttpMethod.PUT, badge))
        .then(response => {
            return handleResponse<Badge>(response)
        });
}

export function deleteBadge(badgeId: string): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/badge/${badgeId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}