import { User } from "../../-account/model/user";
import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { Cohort } from "../model/cohort";
import { CohortStatus } from "../model/cohort-status";
import { Module } from "../model/module";

export function getCohorts(): Promise<Cohort[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Cohort[]>(response)
        });
}

export function getCohort(cohortId: number): Promise<Cohort> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function getCohortStatus(cohortId: number): Promise<CohortStatus> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/status`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<CohortStatus>(response)
        });
}

export function addCohort(cohort: Cohort): Promise<Cohort> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort`, requestOptions(HttpMethod.POST, cohort))
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function updateCohort(cohort: Cohort): Promise<Cohort> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort`, requestOptions(HttpMethod.PUT, cohort))
        .then(response => {
            return handleResponse<Cohort>(response)
        });
}

export function deleteCohort(cohortId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getCohortModules(cohortId: number): Promise<Module[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/module/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<Module[]>(response)
        });
}

export function updateCohortModules(cohortId: number, moduleIds: string[]): Promise<Module[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/module`, requestOptions(HttpMethod.POST, moduleIds))
        .then(response => {
            return handleResponse<Promise<Module[]>>(response)
        });
}

export function getCohortStudents(cohortId: number): Promise<User[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/student/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<User[]>(response)
        });
}

export function updateCohortStudents(cohortId: number, studentIds: number[]): Promise<User[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/cohort/${cohortId}/student`, requestOptions(HttpMethod.POST, studentIds))
        .then(response => {
            return handleResponse<Promise<User[]>>(response)
        });
}