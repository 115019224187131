import { FieldDefinition } from "../../../../component/form/field-definition";
import { courseLevelOptions, moduleCategoryOptions } from "../../../../utils/form-select-input-options";

export const moduleFieldDefinitions: FieldDefinition[] = [
    {
        id: "moduleId",
        name: "Module Id",
        type: "text",
        required: true,
        disabled: false,
        min: 3,
        max: 10
    },
    {
        id: "title",
        name: "Title",
        type: "text",
        required: true,
        disabled: false
    },
    {
        id: "categoryId",
        name: "Category",
        type: "select",
        required: true,
        disabled: false,
        values: moduleCategoryOptions()
    },
    {
        id: "courseLevelId",
        name: "Course level",
        type: "select",
        required: true,
        disabled: false,
        values: courseLevelOptions()
    },
    {
        id: "active",
        name: "Active",
        type: "boolean",
        required: true,
        disabled: false
    }
];