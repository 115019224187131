import { StudentCourseLevels } from "../../-education/model/student-course-levels";
import { HttpMethod, requestOptions } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";
import { BadgeAssignment } from "../model/badge-assignment";
import { ModuleAssignment } from "../model/module-assignment";

export function getStudentAssignments(): Promise<ModuleAssignment[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function submitStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions(HttpMethod.POST, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function dismissStudentAssignment(badgeId: string): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/dismiss/${badgeId}`, requestOptions(HttpMethod.POST))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function updateStudentAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student`, requestOptions(HttpMethod.PUT, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}

export function deleteStudentAssignment(workItemId: number): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/${workItemId}`, requestOptions(HttpMethod.DELETE))
        .then(response => {
            return handleResponseNoContent(response)
        });
}

export function getStudentCourseLevels(): Promise<StudentCourseLevels> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/student/courselevel`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<StudentCourseLevels>(response)
        });
}

export function getEvaluatorAssignments(): Promise<ModuleAssignment[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator/list`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<ModuleAssignment[]>(response)
        });
}

export function updateEvaluatorAssignment(badgeAssignment: BadgeAssignment): Promise<BadgeAssignment> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/assignment/evaluator`, requestOptions(HttpMethod.PUT, badgeAssignment))
        .then(response => {
            return handleResponse<BadgeAssignment>(response)
        });
}