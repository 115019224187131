import React, { useEffect, useState } from "react";
import { User } from "../../../-account/model/user";
import { useToast } from "../../../../context/toast/toast-provider";
import { Cohort } from "../../model/cohort";
import { attendanceFieldDefinitions } from "./attendance-field-definition";
import { AttendanceUpdate } from "../../model/attendance-update";
import { getAttendance, updateAttendances } from "../../service/attendance-service";
import BaseForm from "../../../../component/form/base-form";
import { FieldDefinition } from "../../../../component/form/field-definition";
import { SelectItem } from "../../../../component/form/select-item";
import BaseMultiSelect from "../../../../component/mulit-select/base-multi-select";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export default function AttendanceHandler({
    attendanceUpdate,
    setAttendanceUpdate,
    students,
    cohorts
}: {
    attendanceUpdate: AttendanceUpdate,
    setAttendanceUpdate: React.Dispatch<React.SetStateAction<AttendanceUpdate>>,
    students: User[],
    cohorts: Cohort[]
}) {
    const [submitting, setSubmitting] = useState(false);
    const [selectedCohortStudents, setSelectedCohortStudents] = useState<User[]>([]);

    const toast = useToast();

    useEffect(() => {
        getAttendance(attendanceUpdate.day.format('YYYY-MM-DD'), attendanceUpdate.cohortId).then((result) => {
            setSelectedCohortStudents(students.filter(student => (result ?? []).find(_ => student.id === _.studentId) !== undefined))
        });
    }, [attendanceUpdate.cohortId, attendanceUpdate.day, students]);

    const getFieldDefinitions = (): FieldDefinition[] => {
        attendanceFieldDefinitions
            .find(_ => _.id === "cohortId")!.values = (cohorts !== undefined ? cohorts : [])
                .map<SelectItem>(_ => ({ id: _.id, name: _.name }))
        return attendanceFieldDefinitions;
    }

    const handleSubmit = () => {
        setSubmitting(true);

        attendanceUpdate.addAttendances = selectedCohortStudents.map(student => student.id);
        attendanceUpdate.removeAttendances = students.filter(student => student.cohortId === attendanceUpdate.cohortId && selectedCohortStudents.find(_ => student === _) === undefined).map(student => student.id);

        updateAttendances(attendanceUpdate).then((result) => {
            toast.addToast(`Attendance on ${dayjs(attendanceUpdate.day).format('YYYY-MM-DD')} updated`, "success");

            setSelectedCohortStudents(selectedCohortStudents.filter(student => (result ?? []).find(_ => student.id === _.studentId) !== undefined))
            setSubmitting(false);
        }).catch(() => {
            toast.addToast(`Unable to update attendance on ${dayjs(attendanceUpdate.day).format('YYYY-MM-DD')}`, "error");
            setSubmitting(false);
        });
    }

    return (
        <BaseForm
            model={attendanceUpdate}
            setModel={setAttendanceUpdate}
            fields={getFieldDefinitions()}
            submitEntity={handleSubmit}
            submitting={submitting}
        >
            <BaseMultiSelect
                items={attendanceUpdate.cohortId !== undefined ? students.filter(_ => _.cohortId === attendanceUpdate.cohortId) : []}
                itemIdKey='id'
                selectedItems={selectedCohortStudents}
                setSelectedItems={setSelectedCohortStudents}

                selectedItemsShown={false}

                availableItemsTitle='Attending student(s)'
                availableItemsNoneText={attendanceUpdate.cohortId !== undefined ? 'No students found to select' : 'Please select a cohort'}
                availableItemRender={(user) => <span>{user.name}</span>}

                disabled={submitting || attendanceUpdate.cohortId === undefined}
            />
        </BaseForm>
    );
}
