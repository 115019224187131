import { GridColDef } from "@mui/x-data-grid";
import { TargetGroup } from "../../enum/target-group";
import { Gender } from "../../../enum/gender";
import { renderActive } from "../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1.5 },
    { field: 'courseLevelName', headerName: 'Course level', flex: 1 },
    { field: 'year', headerName: 'Year', flex: 0.5 },
    { field: 'age', headerName: 'Age', flex: 0.5 },
    { field: 'students', headerName: 'Students', flex: 0.5 },
    { field: 'gender', headerName: 'Gender', flex: 0.5, renderCell: (params) => { return renderGender(params.row.gender) } },
    { field: 'targetGroups', headerName: 'Target groups', flex: 1.5, renderCell: (params) => { return renderTargetGroups(params.row.targetGroups) } },
    { field: 'active', headerName: 'Active', flex: 0.5, renderCell: (params) => { return renderActive(params.row.active) } }
];

const renderGender = (gender: number) => {
    return Gender[gender].replace('_', ' ');
}

const renderTargetGroups = (targetGroups: string[]) => {
    return targetGroups.map(_ => TargetGroup[Number(_)].replace('_', ' ')).join(", ");
}