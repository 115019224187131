import { GridColDef } from "@mui/x-data-grid";
import { renderActive } from "../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'moduleId', headerName: 'Id', flex: 0.5 },
    { field: 'title', headerName: 'Title', flex: 2 },
    { field: 'categoryName', headerName: 'Category', flex: 1 },
    { field: 'courseLevelName', headerName: 'Course level', flex: 1 },
    { field: 'totalBadges', headerName: 'Badges', flex: 0.5 },
    { field: 'totalEstimate', headerName: 'Estimate (h)', flex: 0.5 },
    { field: 'active', headerName: 'Active', flex: 0.5, renderCell: (params) => { return renderActive(params.row.active) } }
];