import { CourseLevel } from "../../-education/enum/course-level"

export const getBadgeTerminology = (
    studentCourseLevel: CourseLevel,
    lowerCase?: boolean | undefined,
    plural?: boolean | undefined
) => {
    let term = studentCourseLevel === CourseLevel.Mobile_Leap ? 
        `Session${plural === true ? "s" : ""}` : 
        `Badge${plural === true ? "s" : ""}`;
    return lowerCase === true ? String(term).charAt(0).toLocaleLowerCase() + String(term).slice(1) : term;
}

export const getModuleTerminology = (
    studentCourseLevel: CourseLevel,
    lowerCase?: boolean | undefined,
    plural?: boolean | undefined
) => {
    let term = studentCourseLevel === CourseLevel.Mobile_Leap ?
        `Foundation${plural === true ? "s" : ""}` : 
        `Module${plural === true ? "s" : ""}`;
    return lowerCase === true ? String(term).charAt(0).toLocaleLowerCase() + String(term).slice(1) : term;
}