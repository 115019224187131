import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from 'http-status-codes';
import jwt_decode from "jwt-decode";
import { useToast } from "../toast/toast-provider";
import { AuthContextType } from "./auth-context-type";
import { LMS_PAGE_LOGOUT_RELOAD, LMS_USER } from "../../component/constant";
import { dateTimeReviver } from "../../utils/helper/json-helper";
import { ApiError } from "../../utils/api/api-error";
import { User } from "../../section/-account/model/user";
import { getSelf } from "../../section/-account/service/user-service";
import { Role } from "../../section/enum/role";

let AuthContext = React.createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
    let lastUser: User | null = JSON.parse(localStorage.getItem(LMS_USER)!, dateTimeReviver);
    if (lastUser !== null && ((lastUser.accessTokenExpire !== undefined && lastUser.accessTokenExpire <= new Date()) || !lastUser.accessTokenExpire)) {
        lastUser = null;
        localStorage.removeItem(LMS_USER);
    }

    const [user, setUser] = useState<User | null>(lastUser);
    const [loading, setLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const toast = useToast();

    const getErrorMessage = (errorCode: number): string => {
        switch (StatusCodes[errorCode]) {
            case StatusCodes[StatusCodes.BAD_REQUEST]: return "Invalid login request";
            case StatusCodes[StatusCodes.UNAUTHORIZED]: return "User not found";
            case StatusCodes[StatusCodes.NOT_FOUND]: return "User not found";
            default: return "Unable to login";
        }
    }

    const hasAnyRoles = (roles: Role[]): boolean => {
        return roles.filter(r => user?.roles.find(ur => ur === r) !== undefined).length > 0
    }

    const signIn = (accessToken: string, redirectTo: string) => {
        setLoading(true);
        getSelf(accessToken).then(user => {
            let decodedAccessToken: any = jwt_decode(accessToken);
            user.accessToken = accessToken;
            user.accessTokenExpire = new Date(decodedAccessToken.exp * 1000);
            localStorage.setItem(LMS_USER, JSON.stringify(user));
            setUser(user!);
            setLoading(false);
            navigate(redirectTo, { replace: true });
        }).catch(error => {
            setLoading(false);
            if (error instanceof ApiError) {
                const apiError = error as ApiError
                toast.addToast(getErrorMessage(apiError.apiErrorResponse.errorCode), "error");
            } else {
                toast.addToast(getErrorMessage(-1), "error");
            }
        })
    };

    const signOut = (redirectTo: string) => {
        localStorage.removeItem(LMS_USER);
        setUser(null);
        sessionStorage.setItem(LMS_PAGE_LOGOUT_RELOAD, 'true');
        navigate(redirectTo, { replace: true });
    };

    let value = { user, hasAnyRoles, signIn, signOut, loading };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}