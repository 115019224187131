import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse } from "../../../utils/api/response-handler";
import { SurveySubmit } from "../model/survey-submit";

export function getSurveySubmits(from: string, to: string): Promise<SurveySubmit[]> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/list/${from}/${to}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmit[]>(response)
        });
}

export function getSurveySubmit(surveySubmitId: number): Promise<SurveySubmit> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/survey/submit/${surveySubmitId}`, requestOptions(HttpMethod.GET))
        .then(response => {
            return handleResponse<SurveySubmit>(response)
        });
}