import { GridColDef } from "@mui/x-data-grid";
import { renderDate, renderHoursTimeSpan } from "../../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'cohortName', headerName: 'Cohort', flex: 1 },
    { field: 'moduleTitle', headerName: 'Module', flex: 1 },
    { field: 'badgeTitle', headerName: 'Badge', flex: 1 },
    { field: 'studentName', headerName: 'Student', flex: 1 },
    { field: 'studentEmailAddress', headerName: 'Email', flex: 1 },
    { field: 'firstSubmissionDate', headerName: 'First submission', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'expectedSubmissionDate', headerName: 'Expected submission', flex: 1, valueFormatter: (params) => { return renderDate(params.value, true) } },
    { field: 'delayedHours', headerName: 'Delayed', flex: 0.5, valueFormatter: (params) => { return renderHoursTimeSpan(params.value) } }
];