import { Survey } from "../../-examine/model/survey";
import { SurveySubmit } from "../../-examine/model/survey-submit";
import { requestOptions, HttpMethod } from "../../../utils/api/request-options";
import { handleResponse, handleResponseNoContent } from "../../../utils/api/response-handler";;

export function getSurvey(surveyId: number): Promise<Survey | undefined> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}`, requestOptions(HttpMethod.GET, undefined, false))
        .then(response => {
            if (response.status === 404) {
                return undefined;
            } else {
                return handleResponse<Survey>(response)
            }
        });
}

export function surveySubmit(surveyId: number, surveySubmit: SurveySubmit): Promise<void> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/public/survey/${surveyId}/submit`, requestOptions(HttpMethod.POST, surveySubmit, false))
        .then(response => {
            return handleResponseNoContent(response)
        });
}