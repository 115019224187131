import { HttpMethod, requestOptions } from "../../utils/api/request-options";
import { handleResponse } from "../../utils/api/response-handler";
import { ConfigurationSettings } from "../model/configuration-settings";

export function getVersion(): Promise<string> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/utils/version`, requestOptions(HttpMethod.GET, undefined, false))
        .then(response => { 
            return response.text()
        });
}

export function getSettings(): Promise<ConfigurationSettings> {
    return fetch(`${process.env.REACT_APP_LMS_API_URL}/v3/utils/configuration/settings`, requestOptions(HttpMethod.GET, undefined, false))
        .then(response => { 
            return handleResponse<ConfigurationSettings>(response)
        });
}