import { FieldDefinition } from "../../../../component/form/field-definition";

export const studentSubmitSelectFieldDefinitions: FieldDefinition[] = [
    {
        id: "moduleId",
        name: "Module",
        type: "select",
        required: true,
        disabled: false
    },
    {
        id: "badgeId",
        name: "Badge",
        type: "select",
        required: true,
        disabled: true
    }
];