import { GridColDef } from "@mui/x-data-grid";
import StarIcon from '@mui/icons-material/Star';
import { Student } from "../../model/student";
import { renderDate } from "../../../../utils/table-column-renders";

export const columns: GridColDef[] = [
    { field: 'day', headerName: 'Day', flex: 1, valueFormatter: (params) => { return renderDate(params.value) } },
    { field: 'presenterName', headerName: 'Presenter', flex: 1 },
    { field: 'cohortName', headerName: 'Cohort', flex: 1 },
    { field: 'studentAttendance', headerName: 'Attendance', flex: 0.5, valueFormatter: (params) => { return renderStudentAttendance(params.value) } },
    { field: 'rating', headerName: 'Rating', flex: 0.5, renderCell: (params) => { return renderRating(params.row.rating) } }
];

const renderStudentAttendance = (students?: Student[]) => {
    return students !== undefined ? students.length : '';
}

const renderRating = (rating: number) => {
    let ratingBuffer = [];
    for (let i = 1; i <= rating; i++) {
        ratingBuffer.push(<StarIcon key={`rating-${i}`} color="primary" />);
    }
    return (
        <div>
            {ratingBuffer}
        </div>
    )
}